import React from 'react'
import styled from 'styled-components'
import EggheadVideo from './EggheadVideo'

const VideoList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li.video {
    margin-bottom: 60px;
  }
`

export default function EggheadVideoList({ videos }) {
  return (
    <VideoList>
      {videos.map(video => (
        <li className="video">
          <EggheadVideo
            title={video.node.title}
            src={video.node.url}
            tags={video.node.tags}
          />
        </li>
      ))}
    </VideoList>
  )
}
