import React from 'react'
import { Link } from 'gatsby'
import EggheadEmbed from './EggheadEmbed'
import TagList from '../atoms/TagList'
import Eggo from '../images/eggo.svg'
import styled from 'styled-components'
import { eggheadRefId } from '../config'

const EggheadExtras = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }
`

export default function({ title, src, tags }) {
  return (
    <div>
      <h2>{title}</h2>
      <TagList>
        {tags.map((tag, i) => (
          <li key={i}>
            <Link to={`videos/tags/${tag}`}>#{tag}</Link>
          </li>
        ))}
      </TagList>
      <EggheadEmbed src={src} />
      <a href={`${src}?af=${eggheadRefId}`}>
        <EggheadExtras>
          <Eggo height="35px" />
          <span>View source code and transcript</span>
        </EggheadExtras>
      </a>
    </div>
  )
}
