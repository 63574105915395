import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import EggheadVideoList from '../components/EggheadVideoList'
import Content from '../atoms/Content'
import Newsletter from '../components/Newsletter'

export default function Videos({ data, location }) {
  const siteTitle = data.site.siteMetadata.title
  const videos = data.allEgghead.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Videos"
        keywords={[
          `videos`,
          'javascript',
          'python',
          'react',
          'gatsby',
          'django',
        ]}
        description="Steven Mercatante's videos on JavaScript, Python, React, GatsbyJS, and other web dev topics."
      />
      <Content>
        <h1>Videos</h1>
        <p>
          These videos are hosted on{' '}
          <a href="https://egghead.io/s/1gs5q">Egghead.io</a>. I was a
          subscriber before becoming an instructor with them, and I think
          they're a great resource for anyone interested in learning more about
          web development.
        </p>
        <p>
          <a href="mailto:steve@stevemerc.com">Email me</a> if there's a topic
          you'd like me to record a video on.
        </p>
      </Content>
      <br />
      <EggheadVideoList videos={videos} />
      <Newsletter referrer="videos page" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query AllLessons {
    site {
      siteMetadata {
        title
      }
    }
    allEgghead(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          title
          url
          tags
        }
      }
    }
  }
`
